import React from "react";
import PropTypes from "prop-types";
import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownComponents from "./NavbarDropdownComponents";
import {
  authenticationRoutes,
  chatRoutes,
  componentRoutes,
  ECommerceRoutes,
  emailRoutes,
  homeRoutes,
  dashboardRoutes,
  master,
  AnalyticsRoutes,
  operations,
  userManagement,
  scheduleRoutes,
  tvod,
  avod,
  demands,
  campaign,
  demandReports,
  adsText,
  svod,
  otherReports,
  configSettings,
  marketing,
  pageRoutes,
  pluginRoutes,
  utilityRoutes,
  widgetsRoutes,
  Ecommerce,
  contentPartnerAvod,
  EcommerceAnalytics,
  FinanceRoute,
  affiliateRoutes
} from "../../routes";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { breakpoints } from "../../helpers/utils";
import { topNavbarBreakpoint } from "../../config";

const NavbarTopDropDownMenus = ({ setNavbarCollapsed }) => {
  const components = [
    { children: [] },
    demands,
    campaign,
    demandReports,
    adsText,
  ];
  const pages = [
    pageRoutes,
    widgetsRoutes,
    chatRoutes,
    emailRoutes,
    ECommerceRoutes,
  ];
  let path = '/'
  const handleClick = () => {
    // if(localStorage.getItem('role_id') === 7){
    //   localStorage.setItem("add_server_admin", true);
    // }else{
    localStorage.setItem("add_server_admin", "true");
    // }


  };


  const handleBack = () => {
    localStorage.setItem("add_server_admin", "false");
  };
  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
  };
  const cmsRoutes = dashboardRoutes();
  const svodRoutes = svod();
  const tvodRoutes = tvod();
  const contentPartnerAvodRoutes = contentPartnerAvod();

  const masterRoutes = master();
  const analyticsRoutes = AnalyticsRoutes()
  // const operationsRoutes = operations();
  const userManagementRoutes = userManagement();
  const otherReportsRoutes = otherReports();
  const eCommerceRoutes = Ecommerce();
  const eCommerceAnalyticsRoutes = EcommerceAnalytics();
  const configSettingsRoutes = configSettings();
  const FinanceRoutes = FinanceRoute();
  const marketingRoutes = marketing();
  const pub_id = localStorage.getItem("pub_id");
  const getPublisherConfigInfo = keyName => {
    let response = false;
    if (localStorage.getItem("publisherConfig") && (typeof localStorage.getItem("publisherConfig") !== 'undefined' && (localStorage.getItem("publisherConfig") !== 'undefined'))) {
      var publisherConfig = JSON.parse(localStorage.getItem("publisherConfig"));
      if (publisherConfig) {
        let configInfo = publisherConfig.filter(i => keyName == i.key_name);
        response = configInfo[0] && configInfo[0].key_value;
        return response;
      }
    }
    return response;
  }

  let jsonRoutes = []

  let filteredRoutes = []
  if (localStorage.getItem("routes") != 'undefined') {
    const storedRoutes = JSON.parse(localStorage.getItem("routes"));
    const flattenRoutes = (routes) => {
      let flattened = [];
      routes.forEach((route) => {
        if (route.name) {
          flattened.push(route);
        }
        if (route.children && Array.isArray(route.children)) {
          flattened = flattened.concat(flattenRoutes(route.children));
        }
      });
      return flattened;
    };

    if (storedRoutes?.includes("CMS")) {
      jsonRoutes.push(cmsRoutes);
    }
    if (storedRoutes?.includes("User Management")) {
      jsonRoutes.push(userManagementRoutes);
    }
    if (storedRoutes?.includes("Analytics")) {
      jsonRoutes.push(otherReportsRoutes);
    }
    if (getPublisherConfigInfo("svod_needed") === "True") {
      if (storedRoutes?.includes("SVOD")) {
        jsonRoutes.push(svodRoutes);  // Note: calling the function
      }
    }
    if (getPublisherConfigInfo("avod_needed") === "True") {
      if (storedRoutes?.includes("AVOD")) {
        jsonRoutes.push(avod);  // Note: calling the function
      }
    }
    if (jsonRoutes?.length > 0) {
      const allRoutes = flattenRoutes(jsonRoutes);

      if (storedRoutes && Array.isArray(storedRoutes)) {
        filteredRoutes = allRoutes.filter((route) =>
          storedRoutes.includes(route.name)
        );
        console.log("Filtered Routes:", JSON.stringify(filteredRoutes, null, 2));
      } else {
        console.error("Stored routes are invalid or not found in localStorage.");
      }
    } else {
      console.error("No routes found in `jsonRoutes`.");
    }
  }
  let ContributorRoutesRes = filteredRoutes; // Use the filtered array directly
  let ContributorRoutes = ContributorRoutesRes.map((route, index) => (
    <NavbarDropdown
      key={index}
      title={route.name}
      items={route.children || []}
      handleSetNavbarCollapsed={handleSetNavbarCollapsed}
    />
  ));

  let mainRoutes = (
    <>
      {localStorage.getItem("role_id") != 7 &&
        <NavbarDropdown
          title={homeRoutes.name}
          items={homeRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />}
      <NavbarDropdown
        title={masterRoutes.name}
        items={masterRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      {localStorage.getItem("role_id") == 7 &&
        <NavbarDropdown
          title={analyticsRoutes.name}
          items={analyticsRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />}
      {
        localStorage.getItem("role_id") != 7 && (
          <NavbarDropdown
            title={cmsRoutes.name}
            items={cmsRoutes.children}
            handleSetNavbarCollapsed={handleSetNavbarCollapsed}
          />)
      }
      {/* {
      localStorage.getItem("role_id")!=7 && operationsRoutes && operationsRoutes?.children?.length>0 &&
      <NavbarDropdown
        title={operationsRoutes.name}
        items={operationsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />} */}
      {
        localStorage.getItem("role_id") != 7 &&
        <NavbarDropdown
          title={userManagementRoutes.name}
          items={userManagementRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />}
      {getPublisherConfigInfo("linear_needed") == "True" ? (
        <NavbarDropdown
          title={scheduleRoutes.name}
          items={scheduleRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}

      {getPublisherConfigInfo("tvod_needed") == "True" ? (
        <NavbarDropdown
          title={tvodRoutes.name}
          items={tvodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}

      {getPublisherConfigInfo("avod_needed") == "True" ? (
        <NavbarDropdownComponents
          title={avod.name}
          items={components}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}

      {getPublisherConfigInfo("svod_needed") == "True" ? (
        <NavbarDropdown
          title={svodRoutes.name}
          items={svodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}

      {
        localStorage.getItem("role_id") != 7 && localStorage.getItem("pub_id") != 50034 &&
        <NavbarDropdown
          title={otherReportsRoutes.name}
          items={otherReportsRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />}
      {
        // localStorage.getItem("role_id")!=7 &&
        <NavbarDropdown
          title={affiliateRoutes.name}
          items={affiliateRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />}
      {(getPublisherConfigInfo("ecommerce_needed") == "True" ||
        localStorage.getItem("role_id") == 12) && (
          <>
            <NavbarDropdown
              title={eCommerceRoutes.name}
              items={eCommerceRoutes.children}
              handleSetNavbarCollapsed={handleSetNavbarCollapsed}
            />
            <NavbarDropdown
              title={eCommerceAnalyticsRoutes.name}
              items={eCommerceAnalyticsRoutes.children}
              handleSetNavbarCollapsed={handleSetNavbarCollapsed}
            />
          </>
        )}

      {/* {getPublisherConfigInfo("settings_needed") == "True" ? (
      <NavbarDropdown
        title={configSettingsRoutes.name}
        items={configSettingsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />):null} */}
      {localStorage.getItem("superadmin") ? (
        <NavbarDropdown
          title={configSettingsRoutes.name}
          items={configSettingsRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />) : null}

      {localStorage.getItem("role_id") != 7 && <NavbarDropdown
        title={marketingRoutes.name}
        items={marketingRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />}
      {localStorage.getItem("role_id") == 14 ? (
        <NavbarDropdown
          title={FinanceRoutes.name}
          items={FinanceRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}
      {/* <NavbarDropdown
      title={cmsRoutes.name}
      items={cmsRoutes.children}
      handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      
    /> 
    <NavbarDropdown title={cmsRoutes.name} items={cms} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdown title={pageRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdown
        title={authenticationRoutes.name}
        items={authenticationRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />

      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink tag={Link} to="/documentation">
          Documentation
        </NavLink>
      </NavItem> */}
      {
        getPublisherConfigInfo("ad_server_needed") == "True" || localStorage.getItem("role_id") == 7 ?
          <Link to='/ad-server' onClick={handleClick} style={{ marginTop: '7px', color: '#fff' }}>
            Ad Server
          </Link> : null
      }


      {
        localStorage.getItem("role_id") == 7 ?
          <Link to='/user/role' style={{ marginTop: '7px', color: '#fff', marginLeft: '8px' }}>
            User management
          </Link>
          : null
      }


    </>
  );
  let contentPartnerMainRoutes = (
    <>
      <NavbarDropdown
        title={homeRoutes.name}
        items={homeRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={cmsRoutes.name}
        items={cmsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      {getPublisherConfigInfo("tvod_needed") == "True" ? (
        <NavbarDropdown
          title={tvodRoutes.name}
          items={tvodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}
      {getPublisherConfigInfo("avod_needed") == "True" ? (
        <NavbarDropdown
          title={contentPartnerAvodRoutes.name}
          items={contentPartnerAvodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}
      {getPublisherConfigInfo("svod_needed") == "True" ? (
        <NavbarDropdown
          title={svodRoutes.name}
          items={svodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}
      <NavbarDropdown
        title={otherReportsRoutes.name}
        items={otherReportsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
    </>
  );
  let FinanceTeamRoutes = (
    <>
      <NavbarDropdown
        title={FinanceRoutes.name}
        items={FinanceRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
    </>
  );
  let AddServerMainRoutes = (
    <>

      <NavbarDropdown
        title={demands.name}
        items={demands.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={demandReports.name}
        items={demandReports.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={campaign.name}
        items={campaign.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={adsText.name}
        items={adsText.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <Link to={path} onClick={handleBack} style={{ marginTop: '10px', color: '#fff' }}>
        Back to Home
      </Link>

    </>
  );
  return (localStorage.getItem("role_id") == 8 || localStorage.getItem("role_id") == 17)
    ? contentPartnerMainRoutes
    : localStorage.getItem("role_id") == 13
      ? FinanceTeamRoutes : localStorage.getItem("role_id") == 15
        ? ContributorRoutes :
        localStorage.getItem("add_server_admin") == "true"
          ? AddServerMainRoutes : mainRoutes;
};

NavbarTopDropDownMenus.propTypes = {
  setNavbarCollapsed: PropTypes.func.isRequired,
};

export default NavbarTopDropDownMenus;
